<template>
  <transition name="dialog">
    <div v-if="value" class="dialog" @click="onclick">
      <div class="box">
        <h1 v-if="title" class="header">
          {{ title }}
        </h1>
        <div class="content">
          <slot />
        </div>
        <div class="actions">
          <button class="button" @click="close">
            Close
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: undefined
    }
  },

  methods: {
    onclick(event) {
      if (event.target === event.currentTarget) {
        this.close()
      }
    },
    close() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: hsla(0, 0%, 0%, .75);
  display: grid;
  place-items: center;

  .box {
    width: min(400px, 95vw);
    background-color: hsl(0, 0%, 1000%);
    padding: 30px;
    border-radius: 5px;

    .header {
      font-size: 1.5rem;
      margin-bottom: 20px;
    }

    .actions {
      display: flex;
      gap: 10px;
      justify-content: end;
      margin-top: 30px;
    }
  }
}

.dialog-enter-active,
.dialog-leave-active,
.dialog-enter-active > .box,
.dialog-leave-active > .box {
  transition: all .2s;
}
.dialog-enter, .dialog-leave-to {
  opacity: 0;
}
.dialog-enter > .box, .dialog-leave-to > .box {
  transform: scale(0.8);
}
</style>
