import Tool from './tool'
import { Circle } from '../entities'
import { AddEntityCommand, ModifyEntityCommand } from '../commands'


class CircleTool extends Tool {
  constructor(board) {
    super(board)
    this.drawing = false
    this.circle = null
    this.point = null
    this.color = '#000'
  }

  onMouseDown(point) {
    this.drawing = true
    this.point = point
  }

  onMouseMove(point) {
    if (!this.drawing) {
      return
    }
    if (this.circle == null) {
      this.circle = new Circle(this.point, 0, this.color)
      this.board.execute(new AddEntityCommand(this.circle))
    }
    this.board.execute(new ModifyEntityCommand(this.circle.id, { radius: this.point.distance(point) }))
  }

  onMouseUp() {
    this.stop()
  }

  onMouseLeave() {
    this.stop()
  }

  stop() {
    this.drawing = false
    this.circle = null
    this.point = null
  }
}


export { CircleTool }
export default CircleTool
