class PhoenixAdapter {
  constructor(channel, event='data', prop='data') {
    this.channel = channel
    this.event = event
    this.prop = prop
  }

  join() {
    return new Promise((resolve, reject) => {
      this.channel.join()
        .receive('ok', res => resolve(res.entities))
        .receive('error', err => reject(err))
    })
  }

  send(commands) {
    this.channel.push(this.event, { [this.prop]: commands })
  }

  receive(callback) {
    this.channel.on(this.event, res => callback(res[this.prop]))
  }

  teardown() {
    this.channel.leave()
  }
}

export { PhoenixAdapter }
export default PhoenixAdapter
