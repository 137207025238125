<template>
  <div class="toolbar">
    <div class="buttons">
      <Tools @select="$emit('select-tool', $event)" />
      <Colors @select="$emit('select-color', $event)" />
      <div class="controls">
        <IconButton
          icon="trash"
          size="lg"
          color="error"
          tooltip="Clear board"
          @click="$emit('clear')"
        />
        <IconButton
          icon="user-plus"
          size="lg"
          tooltip="Invite"
          @click="$emit('invite')"
        />
        <IconButton
          icon="download"
          size="lg"
          tooltip="Save to file"
          @click="$emit('download')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Colors from './ToolbarColors'
import Tools from './ToolbarTools'

export default {
  components: {
    Colors,
    Tools
  }
}
</script>

<style lang="scss" scoped>
.toolbar {
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  width: 650px;
  background-color: #fff;
  box-shadow: var(--shadow-2);
  padding: 10px 20px;
  border-radius: 50px;
  margin: 0 auto;

  transform: translateY(20px);
  opacity: 0;
  animation: toolbar-entry .5s .4s forwards;

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .controls {
      display: flex;
      gap: 5px;
    }
  }
}

@keyframes toolbar-entry {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
