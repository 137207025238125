import Tool from './tool'
import { Path } from '../entities'
import { AddEntityCommand, ModifyEntityCommand } from '../commands'


class LineTool extends Tool {
  constructor(board) {
    super(board)
    this.drawing = false
    this.start = null
    this.path = null
    this.color = '#000'
  }

  onMouseDown(point) {
    this.drawing = true
    this.start = point
  }

  onMouseMove(point) {
    if (!this.drawing) {
      return
    }
    if (this.path == null) {
      this.path = new Path([this.start], this.color)
      this.board.execute(new AddEntityCommand(this.path))
    }
    this.board.execute(new ModifyEntityCommand(this.path.id, {
      points: [this.start, point]
    }))
  }

  onMouseUp() {
    this.stop()
  }

  onMouseLeave() {
    this.stop()
  }

  stop() {
    this.drawing = false
    this.path = null
    this.start = null
  }
}


export { LineTool }
export default LineTool
