import Command from './command'


const Type = Object.freeze({
  ADD_ENTITY: 'ADD_ENTITY',
  MODIFY_ENTITY: 'MODIFY_ENTITY',
  EXTEND_ENTITY: 'EXTEND_ENTITY',
  MOVE_ENTITY: 'MOVE_ENTITY',
  DELETE_ENTITY: 'DELETE_ENTITY',
  CLEAR_BOARD: 'CLEAR_BOARD'
})


class AddEntityCommand extends Command {
  constructor(entity) {
    super(Type.ADD_ENTITY)
    this.entity = entity
  }

  execute(board) {
    board.addEntity(this.entity)
  }
}


class ExtendEntityCommand extends Command {
  constructor(entityId, properties) {
    super(Type.EXTEND_ENTITY)
    this.entityId = entityId
    this.properties = properties
  }

  execute(board) {
    const entity = board.getEntityById(this.entityId)
    for (const [key, val] of Object.entries(this.properties)) {
      entity[key].push(...val)
    }
  }
}


class ModifyEntityCommand extends Command {
  constructor(entityId, properties) {
    super(Type.MODIFY_ENTITY)
    this.entityId = entityId
    this.properties = properties
  }

  execute(board) {
    const entity = board.getEntityById(this.entityId)
    for (const [key, val] of Object.entries(this.properties)) {
      entity[key] = val
    }
  }
}


class MoveEntityCommand extends Command {
  constructor(entityId, x, y) {
    super(Type.MOVE_ENTITY)
    this.entityId = entityId
    this.x = x
    this.y = y
  }

  execute(board) {
    board.getEntityById(this.entityId).move(this.x, this.y)
  }
}


class DeleteEntityCommand extends Command {
  constructor(entityId) {
    super(Type.DELETE_ENTITY)
    this.entityId = entityId
  }

  execute(board) {
    board.deleteEntity(this.entityId)
  }
}


class ClearBoardCommand extends Command {
  constructor() {
    super(Type.CLEAR_BOARD)
  }

  execute(board) {
    board.clearEntities()
  }
}


export {
  Type,
  AddEntityCommand,
  ModifyEntityCommand,
  ExtendEntityCommand,
  MoveEntityCommand,
  DeleteEntityCommand,
  ClearBoardCommand
}
