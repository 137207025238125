<template>
  <div class="room">
    <a id="download"></a>
    <canvas ref="canvas"></canvas>
    <Toolbar
      @select-tool="selectTool"
      @select-color="selectColor"
      @clear="clear"
      @download="download"
      @invite="helpDialogOpen = true"
    />
    <Dialog v-model="helpDialogOpen">
      <p class="help-text">Invite others by sharing the room URL:</p>
      <input :value="url" readonly @click="e => e.target.select()" class="url" />
    </Dialog>
  </div>
</template>

<script>
import socket from '@/socket'
import { Board } from '@/lib/board'
import { NetworkPlugin, PhoenixAdapter, UndoRedoPlugin } from '@/lib/plugins'
import { ToolManager, MoveTool, PenTool, RectangleTool, CircleTool, LineTool } from '@/lib/tools'
import { ClearBoardCommand } from '@/lib/commands'
import Toolbar from '@/components/Toolbar'
import Dialog from '@/widgets/Dialog'

const KNOWN_ERROR_REASONS = ['not_found', 'inactivity_exit']

let board, manager, channel

export default {
  data() {
    return {
      helpDialogOpen: false
    }
  },

  computed: {
    url() {
      return `${window.location.origin}${this.$route.fullPath}`
    }
  },

  components: {
    Toolbar,
    Dialog
  },

  mounted() {
    channel = socket.channel('room:' + this.$route.params.id)
    channel.on('exit', this.onError)
    const phoenixAdapter = new PhoenixAdapter(channel)
    const plugins = [
      new NetworkPlugin(phoenixAdapter, { onJoinError: this.onError }),
      new UndoRedoPlugin()
    ]
    board = new Board(this.$refs.canvas, plugins, { fullscreen: true })
    const tools = {
      move: new MoveTool(board),
      pen: new PenTool(board),
      rectangle: new RectangleTool(board),
      circle: new CircleTool(board),
      line: new LineTool(board)
    }
    manager = new ToolManager(tools)
  },

  beforeDestroy() {
    board.teardown()
    board = undefined
    manager = undefined
    channel = undefined
  },

  methods: {
    onError(err) {
      if (KNOWN_ERROR_REASONS.includes(err.reason)) {
        window.alert(err.message)
        this.$router.push('/')
      }
      // On unexpected errors, we let the phoenix channel handle reconnecting
      console.error(err)
    },

    async selectTool(tool) {
      if (manager == null) {
        await this.$nextTick()
      }
      manager.enable(tool)
    },

    async selectColor(color) {
      if (manager == null) {
        await this.$nextTick()
      }
      manager.setColor(color)
    },

    clear() {
      const confirmed = window.confirm('Clear the board?')
      if (confirmed)
        board.execute(new ClearBoardCommand())
    },

    download() {
      const el = document.querySelector('#download')

      const filename = `${this.$route.params.id}.png`
      el.setAttribute('download', filename)

      const image = board.canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      el.setAttribute('href', image)

      el.click()
    }
  }
}
</script>

<style lang="scss" scoped>
canvas {
  position: fixed;
}

#download {
  display: none;
}

.help-text {
  font-size: 1.15rem;
  color: hsl(0, 0%, 30%);
  margin-bottom: 20px;
}

.url {
  width: 100%;
  font-size: 1.15rem;
  border: 2px solid hsl(0, 0%, 30%);
  border-radius: 5px;
  padding: 10px;
}
</style>
