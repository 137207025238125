const defaultOptions = {
  fullscreen: false,
  lineWidth: 3,
  lineJoin: 'round'
}


class Board {
  constructor(canvas, plugins=[], options={}) {
    if (typeof canvas == 'string')
      this.canvas = document.querySelector(canvas)
    else
      this.canvas = canvas
    this.ctx = this.canvas.getContext('2d')
    this.plugins = plugins
    this.options = Object.assign({}, defaultOptions, options)
    this.entities = []
    this.entitiesById = {}
    this.init()
    this.run()
  }

  init() {
    if (this.options.fullscreen) {
      this.resizeHandler = () => {
        this.canvas.width = window.innerWidth
        this.canvas.height = window.innerHeight
      }
      this.resizeHandler()
      window.addEventListener('resize', this.resizeHandler, { passive: true })
    }
    this.plugins.forEach(plugin => plugin.init(this))
  }

  run() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.ctx.lineWidth = this.options.lineWidth
    this.ctx.lineJoin = this.options.lineJoin
    this.entities.forEach(entity => entity.draw(this.ctx))
    window.requestAnimationFrame(() => this.run())
  }

  addEntity(entity) {
    this.entities.push(entity)
    this.entitiesById[entity.id] = entity
  }

  deleteEntity(entityId) {
    this.entities = this.entities.filter(entity => entity.id !== entityId)
    delete this.entitiesById[entityId]
  }

  getEntityById(id) {
    return this.entitiesById[id]
  }

  getEntityAtPoint(point) {
    return this.entities.find(e => e.pointInside(point))
  }

  clearEntities() {
    this.entities = []
    this.entitiesById = {}
  }

  execute(command) {
    this.plugins.forEach(plugin => plugin.beforeCommand(command))
    const result = command.execute(this)
    this.plugins.forEach(plugin => plugin.afterCommand(command, result))
    return result
  }

  teardown() {
    window.removeEventListener('resize', this.resizeHandler)
    this.plugins.forEach(plugin => plugin.teardown())
  }
}


export { Board }
export default Board
