import Tool from './tool'
import { Rectangle } from '../entities'
import { AddEntityCommand, ModifyEntityCommand } from '../commands'


class RectangleTool extends Tool {
  constructor(board) {
    super(board)
    this.drawing = false
    this.rectangle = null
    this.point = null
    this.color = '#000'
  }

  onMouseDown(point) {
    this.drawing = true
    this.point = point
  }

  onMouseMove(point) {
    if (!this.drawing) {
      return
    }
    if (this.rectangle == null) {
      this.rectangle = new Rectangle(this.point, 0, 0, this.color)
      this.board.execute(new AddEntityCommand(this.rectangle))
    }
    this.board.execute(new ModifyEntityCommand(this.rectangle.id, {
      width: point.x - this.point.x,
      height: point.y - this.point.y
    }))
  }

  onMouseUp() {
    this.stop()
  }

  onMouseLeave() {
    this.stop()
  }

  stop() {
    this.drawing = false
    this.rectangle = null
    this.point = null
  }
}


export { RectangleTool }
export default RectangleTool
