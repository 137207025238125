import { v4 } from 'uuid'
import { Path, Rectangle, Circle, Type } from '.'
import Point from '../point'

class Entity {
  constructor(type) {
    this.type = type
    this.id = v4()
    this.tolerance = 30
  }

  move() {}

  draw() {}

  // eslint-disable-next-line
  pointInside(point) {
    return false
  }

  toString() {
    return `<Entity type=${this.type}>`
  }

  copy() {
    return Entity.fromResponse(JSON.parse(JSON.stringify(this)))
  }

  static fromResponse(res) {
    if (res.point) {
      res.point = Point.fromResponse(res.point)
    }
    if (res.points) {
      res.points = res.points.map(p => Point.fromResponse(p))
    }
    let entity
    switch (res.type) {
      case Type.PATH:
        entity = new Path(res.points, res.color)
        break
      case Type.RECTANGLE:
        entity = new Rectangle(res.point, res.width, res.height, res.color)
        break
      case Type.CIRCLE:
        entity = new Circle(res.point, res.radius, res.color)
        break
      default:
        throw 'Unknown entity type'
    }
    entity.id = res.id
    return entity
  }
}

export { Entity }
export default Entity
