import Tool from './tool'
import { MoveEntityCommand } from '../commands'

class MoveTool extends Tool {
  constructor(board) {
    super(board)
    this.entity = null
    this.point = null
  }

  onMouseDown(point) {
    const entity = this.board.getEntityAtPoint(point)
    if (entity == null)
      return
    this.entity = entity
    this.point = point
  }

  onMouseMove(point) {
    if (this.entity == null) {
      return
    }
    this.board.execute(new MoveEntityCommand(
      this.entity.id,
      point.x - this.point.x,
      point.y - this.point.y
    ))
    this.point = point
  }

  onMouseUp() {
    this.entity = null
  }

  onMouseLeave() {
    this.entity = null
  }
}

export { MoveTool }
export default MoveTool
