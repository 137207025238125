import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import socket from './socket'
import './icons'

Vue.config.productionTip = false

// Register widgets globally
const requireComponent = require.context('./widgets', true, /\w+\.(vue|js)$/)
requireComponent.keys().forEach((fileName) => {
  const config = requireComponent(fileName)
  const name = fileName.split('/').pop().replace(/\.\w+$/, '')
  Vue.component(name, config.default || config)
})

// Connect to websocket
try {
  socket.connect()
} catch (err) {
  console.error(err)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
