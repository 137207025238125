import Entity from './entity'
import Type from './type'
import { chunk_every } from '../util'

class Path extends Entity {
  constructor(points, color) {
    super(Type.PATH)
    this.points = points
    this.color = color
  }

  addPoint(point) {
    this.points.push(point)
  }

  move(x, y) {
    this.points.forEach((point) => {
      point.x += x
      point.y += y
    })
  }

  draw(ctx) {
    if (this.points.length < 2) {
      return
    }
    const [firstPoint, ...points] = this.points
    ctx.strokeStyle = this.color
    ctx.beginPath()
    ctx.moveTo(firstPoint.x, firstPoint.y)
    points.forEach((point) => {
      ctx.lineTo(point.x, point.y)
    })
    ctx.stroke()
  }

  pointInside(point) {
    // Weird algo
    for (const [p1, p2] of chunk_every(this.points, 2, 1, true)) {
      const a = point.distance(p1) + point.distance(p2)
      const b = p1.distance(p2)
      if (a - b < this.tolerance)
        return true
    }
    return false
  }

  toString() {
    return `<Path points=[${this.points.join(', ')}]>`
  }
}

export { Path }
export default Path
