import Point from '../point'


class Tool {
  constructor(board) {
    this.board = board
    this._listeners = []

    if (this.onMouseEnter)
      this._listeners.push(['mouseenter', this.onMouseEnter.bind(this)])

    if (this.onMouseDown)
      this._listeners.push(['mousedown', e => this.onMouseDown(new Point(e.offsetX, e.offsetY))])
      this._listeners.push(['touchstart', e => this.onMouseDown(new Point(e.touches[0].clientX, e.touches[0].clientY))])

    if (this.onMouseMove)
      this._listeners.push(['mousemove', e => this.onMouseMove(new Point(e.offsetX, e.offsetY))])
      this._listeners.push(['touchmove', e => this.onMouseMove(new Point(e.touches[0].clientX, e.touches[0].clientY))])

    if (this.onMouseUp)
      this._listeners.push(['mouseup', this.onMouseUp.bind(this)])
      this._listeners.push(['touchend', this.onMouseUp.bind(this)])


    if (this.onMouseLeave)
      this._listeners.push(['mouseleave', this.onMouseLeave.bind(this)])
  }


  enable() {
    this._listeners.forEach(([event, func]) => {
      this.board.canvas.addEventListener(event, func, { passive: true })
    })
  }


  disable() {
    this._listeners.forEach(([event, func]) => {
      this.board.canvas.removeEventListener(event, func)
    })
  }
}


export { Tool }
export default Tool
