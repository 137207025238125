class ToolManager {
  constructor(tools) {
    this.tools = tools
  }

  enable(name) {
    Object.values(this.tools).forEach(tool => tool.disable())
    this.tools[name].enable()
  }

  setColor(color) {
    Object.values(this.tools).forEach((tool) => {
      if (tool.color !== undefined)
        tool.color = color
    })
  }
}

export { ToolManager }
export default ToolManager
