import Tool from './tool'
import Path from '../entities/path'
import { AddEntityCommand, ExtendEntityCommand } from '../commands'


class PenTool extends Tool {
  constructor(board) {
    super(board)
    this.drawing = false
    this.path = null
    this.start = null
    this.color = '#000'
  }

  onMouseDown(point) {
    this.drawing = true
    this.start = point
  }

  onMouseMove(point) {
    if (!this.drawing) {
      return
    }
    if (this.path == null) {
      this.path = new Path([this.start], this.color)
      this.board.execute(new AddEntityCommand(this.path))
    }
    this.board.execute(new ExtendEntityCommand(this.path.id, { points: [point] }, true))
  }

  onMouseUp() {
    this.stop()
  }

  onMouseLeave() {
    this.stop()
  }

  stop() {
    this.drawing = false
    this.path = null
    this.start = null
  }
}


export { PenTool }
export default PenTool
