import Entity from './entity'
import Type from './type'

class Circle extends Entity {
  constructor(point, radius, color) {
    super(Type.CIRCLE)
    this.point = point
    this.radius = radius
    this.color = color
  }

  move(x, y) {
    this.point.x += x
    this.point.y += y
  }

  draw(ctx) {
    if (this.radius === 0) {
      return
    }
    ctx.strokeStyle = this.color
    ctx.beginPath()
    ctx.arc(
      this.point.x,
      this.point.y,
      this.radius,
      0, 2 * Math.PI, false
    )
    ctx.stroke()
  }

  pointInside(point) {
    return Math.abs(point.distance(this.point) - this.radius) < this.tolerance
  }

  toString() {
    return `<Circle point=${this.point} radius=${this.radius}>`
  }
}

export { Circle }
export default Circle
