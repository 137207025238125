import {
  Type,
  AddEntityCommand,
  ModifyEntityCommand,
  ExtendEntityCommand,
  MoveEntityCommand,
  DeleteEntityCommand,
  ClearBoardCommand
} from '.'

import { Entity } from '../entities'
import Point from '../point'


class Command {
  constructor(type) {
    this.type = type
  }

  execute() {}

  toString() {
    return `<Command type=${this.type}>`
  }

  static fromResponse(res) {
    // Create point objects if necessary
    if (res.properties?.points != null) {
      res.properties.points = res.properties.points.map(p => Point.fromResponse(p))
    }

    // Create command
    switch (res.type) {
      case Type.ADD_ENTITY:
        return new AddEntityCommand(Entity.fromResponse(res.entity))
      case Type.MODIFY_ENTITY:
        return new ModifyEntityCommand(res.entityId, res.properties)
      case Type.EXTEND_ENTITY:
        return new ExtendEntityCommand(res.entityId, res.properties)
      case Type.MOVE_ENTITY:
        return new MoveEntityCommand(res.entityId, res.x, res.y)
      case Type.DELETE_ENTITY:
        return new DeleteEntityCommand(res.entityId)
      case Type.CLEAR_BOARD:
        return new ClearBoardCommand()
    }
    throw 'Unknown command type'
  }
}


export { Command }
export default Command
