import { BACKEND, DEV } from "./constants"

class Api {
  constructor() {
    this.baseUrl = `http${DEV ? '' : 's'}://${BACKEND}/api`
  }

  createRoom() {
    return this._post('/create-room').then(res => res.id)
  }

  _post(url, data) {
    return this._request(url, 'POST', data)
  }

  async _request(url, method, data) {
    const opts = {
      method,
      headers: { 'Accept': 'application/json' }
    }
    if (data != null) {
      opts.headers['Content-Type'] = 'application/json'
      opts.body = JSON.stringify(data)
    }
    const res = await fetch(this.baseUrl + url, opts)
    return res.json()
  }
}

const api = new Api()

export { api }
export default api
