class Plugin {
  constructor() {}
  init(board) {
    this.board = board
  }
  beforeCommand() {}
  afterCommand() {}
  teardown() {}
}

export { Plugin }
export default Plugin
