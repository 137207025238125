<template>
  <div class="colors">
    <label
      v-for="(color, index) in colors"
      :key="index"
      data-microtip-position="top"
      role="tooltip"
      :aria-label="color.name"
    >
      <input type="radio" :value="index" v-model="activeColor">
      <div
        class="color"
        :style="{ backgroundColor: color.hex }"
      >
      </div>
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      colors: [
        { hex: '#000000', name: 'Black' },
        { hex: '#E53E3E', name: 'Red' },
        { hex: '#FACC15', name: 'Yellow' },
        { hex: '#38A169', name: 'Green' },
        { hex: '#3182CE', name: 'Blue' },
        { hex: '#EC4899', name: 'Pink' },
      ],
      activeColor: 0
    }
  },

  watch: {
    activeColor: {
      immediate: true,
      handler(index) {
        this.$emit('select', this.colors[index].hex)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.colors {
  display: flex;

  label {
    cursor: pointer;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    input {
      cursor: pointer;
      position: fixed;
      width: 0;
      height: 0;
      opacity: 0;

      &:hover ~ .color,
      &:focus ~ .color {
        transform: scale(.8);
      }

      &:checked ~ .color {
        transform: scale(1);
      }
    }

    .color {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      transform: scale(.6);
      transition: all .2s;
    }
  }
}
</style>
