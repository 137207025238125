<template>
  <div class="tools">
    <label
      v-for="(tool, i) in availableTools"
      :key="i"
    >
      <input type="radio" :value="tool.value" v-model="activeTool">
      <IconButton
        :icon="tool.icon"
        :type="tool.iconType || 'solid'"
        :tooltip="tool.name"
        size="lg"
        tag="div"
      />
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      availableTools: [
        { value: 'move', name: 'Move', icon: 'arrows-alt' },
        { value: 'pen', name: 'Pen', icon: 'pen' },
        { value: 'rectangle', name: 'Rectangle', icon: 'square', iconType: 'regular' },
        { value: 'circle', name: 'Circle', icon: 'circle', iconType: 'regular' },
        { value: 'line', name: 'Line', icon: 'slash' },
      ],
      activeTool: 'pen'
    }
  },

  watch: {
    activeTool: {
      immediate: true,
      handler(tool) {
        this.$emit('select', tool)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tools {
  display: flex;

  label {
    cursor: pointer;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    input {
      cursor: pointer;
      position: fixed;
      width: 0;
      height: 0;
      opacity: 0;

      &:focus ~ .icon-button {
        background-color: #eee;
      }

      &:checked ~ .icon-button {
        background-color: #bbb;
      }
    }
  }
}
</style>
