import Plugin from './plugin'
import { Command, Type, AddEntityCommand } from '../commands'
import { Entity } from '../entities'


const defaultOptions = Object.freeze({
  tickrate: 16,
  onJoinError: err => console.error(err)
})


class NetworkPlugin extends Plugin {
  constructor(adapter, options) {
    super()
    this.adapter = adapter
    this.options = Object.assign({}, defaultOptions, options)
    this.commands = []
    this.adapter.receive(this._receive.bind(this))
    this.interval = window.setInterval(this._send.bind(this), 1000 / this.options.tickrate)
  }

  init(board) {
    this.board = board
    this.adapter.join()
      .then((entities) => {
        entities.forEach(ent => {
          const entity = Entity.fromResponse(ent)
          this.board.addEntity(entity)
        })
      })
      .catch(err => this.options.onJoinError(err))
  }

  afterCommand(command) {
    if (command.type === Type.CLEAR_BOARD) {
      // Board cleared, discard previous commands
      this.commands = [command]
      return
    }
    if (command.type === Type.ADD_ENTITY) {
      // Copy entity because a later command can override it,
      // which would result in duplicate data being sent
      command = new AddEntityCommand(command.entity.copy())
    }
    // Try to merge into previous command to save bandwidth
    const prev = this.commands[this.commands.length - 1]
    const merged = this._tryMergeCommands(prev, command)
    if (merged)
      return
    // Append new command
    this.commands.push(command)
  }

  _tryMergeCommands(prev, next) {
    if (prev == null || next == null ||
        prev.type !== next.type ||
        prev.entityId !== next.entityId) {
      return false
    }
    if (prev.type === Type.MODIFY_ENTITY) {
      Object.assign(prev.properties, next.properties)
      return true
    }
    if (prev.type === Type.EXTEND_ENTITY) {
      for (const key of Object.keys(next.properties)) {
        if (prev.properties[key]) {
          prev.properties[key].push(...next.properties[key])
        } else {
          prev.properties[key] = next.properties[key]
        }
      }
      return true
    }
    if (prev.type === Type.MOVE_ENTITY) {
      prev.x += next.x
      prev.y += next.y
      return true
    }
    return false
  }

  _send() {
    if (this.commands.length === 0) {
      return
    }
    this.adapter.send(this.commands)
    this.commands = []
  }

  _receive(commands) {
    commands.forEach((cmd) => {
      Command.fromResponse(cmd).execute(this.board)
    })
  }

  teardown() {
    window.clearInterval(this.interval)
    this.adapter.teardown()
  }
}


export { NetworkPlugin }
export default NetworkPlugin
