import Plugin from './plugin'
import { Type, AddEntityCommand, DeleteEntityCommand } from '../commands'


const defaultOptions = Object.freeze({
  undoKey: 'z',
  redoKey: 'y'
})


class UndoRedoPlugin extends Plugin {
  constructor(options) {
    super()
    this.options = Object.assign({}, defaultOptions, options)
    this.listener = ['keyup', this.handleKey.bind(this)]
    this.undoStack = []
    this.redoStack = []
  }

  init(board) {
    this.board = board
    document.addEventListener(...this.listener)
  }

  afterCommand(command) {
    if (command.type === Type.ADD_ENTITY) {
      this.undoStack.push(command.entity)
    }
  }

  handleKey(event) {
    if (event.ctrlKey) {
      if (event.key === this.options.undoKey) {
        this.undo()
      }
      if (event.key === this.options.redoKey) {
        this.redo()
      }
    }
  }

  undo() {
    if (this.undoStack.length === 0) {
      return
    }
    const entity = this.undoStack.pop()
    this.redoStack.push(entity)
    const command = new DeleteEntityCommand(entity.id)
    this.board.execute(command)
  }

  redo() {
    if (this.redoStack.length === 0) {
      return
    }
    const entity = this.redoStack.pop()
    const command = new AddEntityCommand(entity)
    this.board.execute(command)
  }

  teardown() {
    document.removeEventListener(...this.listener)
  }
}


export { UndoRedoPlugin }
export default UndoRedoPlugin
