<template>
  <transition
    appear
    name="home"
  >
    <div class="home">
      <div class="main">
        <h1>Draw</h1>
        <p>A collaborative drawing application.</p>
        <button class="button primary" @click="createRoom">
          Create a room
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import api from '@/api'

export default {
  methods: {
    async createRoom() {
      const roomId = await api.createRoom()
      this.$router.push(`/${roomId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  height: 100%;

  .main {
    position: absolute;
    top: 0;
    left: 0;
    width: 500px;
    height: 100%;
    background-color: var(--color-secondary-bg);
    padding: 100px 0 0 100px;
    clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);

    h1 {
      font-family: 'Patrick Hand', sans-serif;
      font-size: 5rem;
    }

    p {
      font-size: 1.1rem;
      color: var(--color-secondary-bg-text);
      margin-bottom: 50px;
    }
  }
}

.home-enter-active, .home-leave-active {
  transition: all .2s;
}
.home-enter, .home-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}
</style>
