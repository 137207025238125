import Entity from './entity'
import Type from './type'

class Rectangle extends Entity {
  constructor(point, width, height, color) {
    super(Type.RECTANGLE)
    this.point = point
    this.width = width
    this.height = height
    this.color = color
  }

  move(x, y) {
    this.point.x += x
    this.point.y += y
  }

  draw(ctx) {
    ctx.strokeStyle = this.color
    ctx.strokeRect(
      this.point.x,
      this.point.y,
      this.width,
      this.height
    )
  }

  pointInside(point) {
    // x exact
    if (Math.abs(point.x - this.point.x) < this.tolerance ||
        Math.abs(point.x - (this.point.x + this.width)) < this.tolerance)
    {
      // y along height
      if (point.y > (this.point.y - this.tolerance) &&
          point.y < (this.point.y + this.height + this.tolerance))
      {
        return true
      }
    }

    // y exact
    if (Math.abs(point.y - this.point.y) < this.tolerance ||
        Math.abs(point.y - (this.point.y + this.height)) < this.tolerance)
    {
      // x along width
      if (point.x > (this.point.x - this.tolerance) &&
          point.x < (this.point.x + this.width + this.tolerance))
      {
        return true
      }
    }
  }

  toString() {
    return `<Rectangle point=${this.point} width=${this.width} height=${this.height}>`
  }
}

export { Rectangle }
export default Rectangle
