<template>
  <FontAwesomeIcon
    v-bind="$attrs"
    :icon="faIcon"
  />
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  inheritAttrs: false,

  components: {
    FontAwesomeIcon
  },

  props: {
    icon: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'solid'
    }
  },

  computed: {
    faIcon() {
      let style = 'fas'
      if (this.type === 'regular')
        style = 'far'
      return [style, this.icon]
    }
  }
}
</script>
