export function* range(start, end, step=1) {
  for (let i = start; i < end; i += step) {
    yield i
  }
}


export function* chunk_every(array, size, step, discard=false) {
  if (step == null) {
    step = size
  }
  if (step < 1) {
    throw 'Step must be >= 1'
  }
  let i = 0
  while (i < array.length) {
    let chunk = []
    for (let j = 0; j < size; j++) {
      if (i + j >= array.length) {
        break
      }
      chunk.push(array[i + j])
    }
    if (discard && chunk.length < size) {
      break
    }
    yield chunk
    i += step
  }
}
