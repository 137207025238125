import { Socket } from 'phoenix'
import { BACKEND, DEV } from './constants'

const socket = new Socket(
  `ws${DEV ? '' : 's'}://${BACKEND}/socket`,
  { logger: (kind, msg, data) => DEV ? console.debug(`${kind}: ${msg}`, data) : undefined }
)

export { socket }
export default socket
