<template>
  <component
    :is="tag"
    v-on="$listeners"
    class="icon-button"
    data-microtip-position="top"
    :aria-label="tooltip"
    :role="tooltip !== undefined ? 'tooltip' : undefined"
  >
    <Icon
      v-bind="$attrs"
      class="icon"
      :style="{ color: `var(--color-${color}, #000)` }"
    />
  </component>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    tag: {
      type: String,
      default: 'button'
    },
    color: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-button {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  font-size: 1rem;
  background-color: inherit;
  transition: all .2s;

  &:hover,
  &:focus {
    background-color: #eee;
  }

  &:active {
    background-color: #bbb;
  }
}
</style>
